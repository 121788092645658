import React from 'react'
import {styled} from "@mui/material"
const Wrapper  = styled ("div") ({
  "& .poligon": {
    display: "inline-block",
    position:" relative",
    width: "150px",
    height: "150px",
    background: "linear-gradient(131.88deg, #A73EE7 14.48%, #00EBFF 83.43%)",
    boxSizing: "border-box",
    // transform:"rotate(90deg)",
    webkitClipPath: "polygon(40% 92.32051%, 43.1596% 93.79385%, 46.52704% 94.69616%, 50% 95%, 53.47296% 94.69616%, 56.8404% 93.79385%, 60% 92.32051%,81.65064% 79.82051%, 84.50639% 77.82089%, 86.97152% 75.35575%, 88.97114% 72.5%, 90.44449% 69.3404%, 91.34679% 65.97296%, 91.65064% 62.5%, 91.65064% 37.5%, 91.34679% 34.02704%, 90.44449% 30.6596%, 88.97114% 27.5%, 86.97152% 24.64425%, 84.50639% 22.17911%, 81.65064% 20.17949%,60% 7.67949%, 56.8404% 6.20615%, 53.47296% 5.30384%, 50% 5%, 46.52704% 5.30384%, 43.1596% 6.20615%, 40% 7.67949%, 18.34936% 20.17949%, 15.49361% 22.17911%, 13.02848% 24.64425%, 11.02886% 27.5%, 9.55551% 30.6596%, 8.65321% 34.02704%, 8.34936% 37.5%, 8.34936% 62.5%, 8.65321% 65.97296%, 9.55551% 69.3404%, 11.02886% 72.5%, 13.02848% 75.35575%, 15.49361% 77.82089%, 18.34936% 79.82051%)",
    clipPath: "polygon(40% 92.32051%, 43.1596% 93.79385%, 46.52704% 94.69616%, 50% 95%, 53.47296% 94.69616%, 56.8404% 93.79385%, 60% 92.32051%,81.65064% 79.82051%, 84.50639% 77.82089%, 86.97152% 75.35575%, 88.97114% 72.5%, 90.44449% 69.3404%, 91.34679% 65.97296%, 91.65064% 62.5%, 91.65064% 37.5%, 91.34679% 34.02704%, 90.44449% 30.6596%, 88.97114% 27.5%, 86.97152% 24.64425%, 84.50639% 22.17911%, 81.65064% 20.17949%,60% 7.67949%, 56.8404% 6.20615%, 53.47296% 5.30384%, 50% 5%, 46.52704% 5.30384%, 43.1596% 6.20615%, 40% 7.67949%, 18.34936% 20.17949%, 15.49361% 22.17911%, 13.02848% 24.64425%, 11.02886% 27.5%, 9.55551% 30.6596%, 8.65321% 34.02704%, 8.34936% 37.5%, 8.34936% 62.5%, 8.65321% 65.97296%, 9.55551% 69.3404%, 11.02886% 72.5%, 13.02848% 75.35575%, 15.49361% 77.82089%, 18.34936% 79.82051%)"
  },
  "& img": {
    position: "absolute",
    top: "2px", /* equal to border thickness */
    left: "2px", /* equal to border thickness */
    width: "146px", /* container height - (border thickness * 2) */
    height: "146px", /* container height - (border thickness * 2) */
    //  transform:"rotate(-90deg)",
    webkitClipPath: "polygon(40% 92.32051%, 43.1596% 93.79385%, 46.52704% 94.69616%, 50% 95%, 53.47296% 94.69616%, 56.8404% 93.79385%, 60% 92.32051%,81.65064% 79.82051%, 84.50639% 77.82089%, 86.97152% 75.35575%, 88.97114% 72.5%, 90.44449% 69.3404%, 91.34679% 65.97296%, 91.65064% 62.5%, 91.65064% 37.5%, 91.34679% 34.02704%, 90.44449% 30.6596%, 88.97114% 27.5%, 86.97152% 24.64425%, 84.50639% 22.17911%, 81.65064% 20.17949%,60% 7.67949%, 56.8404% 6.20615%, 53.47296% 5.30384%, 50% 5%, 46.52704% 5.30384%, 43.1596% 6.20615%, 40% 7.67949%, 18.34936% 20.17949%, 15.49361% 22.17911%, 13.02848% 24.64425%, 11.02886% 27.5%, 9.55551% 30.6596%, 8.65321% 34.02704%, 8.34936% 37.5%, 8.34936% 62.5%, 8.65321% 65.97296%, 9.55551% 69.3404%, 11.02886% 72.5%, 13.02848% 75.35575%, 15.49361% 77.82089%, 18.34936% 79.82051%)",
    clipPath: "polygon(40% 92.32051%, 43.1596% 93.79385%, 46.52704% 94.69616%, 50% 95%, 53.47296% 94.69616%, 56.8404% 93.79385%, 60% 92.32051%,81.65064% 79.82051%, 84.50639% 77.82089%, 86.97152% 75.35575%, 88.97114% 72.5%, 90.44449% 69.3404%, 91.34679% 65.97296%, 91.65064% 62.5%, 91.65064% 37.5%, 91.34679% 34.02704%, 90.44449% 30.6596%, 88.97114% 27.5%, 86.97152% 24.64425%, 84.50639% 22.17911%, 81.65064% 20.17949%,60% 7.67949%, 56.8404% 6.20615%, 53.47296% 5.30384%, 50% 5%, 46.52704% 5.30384%, 43.1596% 6.20615%, 40% 7.67949%, 18.34936% 20.17949%, 15.49361% 22.17911%, 13.02848% 24.64425%, 11.02886% 27.5%, 9.55551% 30.6596%, 8.65321% 34.02704%, 8.34936% 37.5%, 8.34936% 62.5%, 8.65321% 65.97296%, 9.55551% 69.3404%, 11.02886% 72.5%, 13.02848% 75.35575%, 15.49361% 77.82089%, 18.34936% 79.82051%)",
  }
})

const PolygonComponent = ({image, polygonStyle={height:"34px", width:"34px"}, imageStyle={height:"32px", width:"32px", backgroundColor:"transparent"}}) => {
    const {width } = polygonStyle
    const {height } = imageStyle
    const widthInNumber = parseInt(width.slice(0, -2))
    const heightInNumber = parseInt(height.slice(0, -2))


    
    let border = Number((widthInNumber - heightInNumber)/2)
    console.log(border)
    console.log(widthInNumber)

  return (
    <Wrapper>
        <div className='poligon' style={polygonStyle}>
        <img src={image} alt="" style={{...imageStyle, top:`${border}px`, left: `${border}px`} }/></div>
    </Wrapper>
  )
}

export default PolygonComponent